<template>
  <div>
    <slot name="regular">
      <span
        :class="{ 'display-none': !regular || special }"
        class="sf-price__regular"
      >
        {{ regular }}
      </span>
    </slot>
    <slot name="old">
      <del :class="{ 'display-none': !special }" class="sf-price__old">
        {{ regular }}
      </del>
    </slot>
    <slot name="special" >
      <ins
        :class="{ 'display-none': !special }"
        class="sf-price__special"
      >
        {{ special }}
      </ins>
    </slot>
  </div>
</template>
<script>
export default {
  name: "Price",
  props: {
    regular: {
      type: [String, Number],
      default: null,
    },
    special: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/atoms/SfPrice.scss";
</style>
